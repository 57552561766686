import { TaskType } from './apollo-task.constants';

export enum HarvesterBlockId {
    File = 'dc.FilesHarvester',
    OtherFile = 'dc.OtherFilesHarvester',
    LargeFiles = 'dc.LargeFilesHarvester',
    Kafka = 'dc.KafkaHarvester',
    MQTT = 'dc.MqttHarvester',
    ExternalKafka = 'dc.ExternalKafkaHarvester',
    ExternalMQTT = 'dc.ExternalMqttHarvester',
    Api = 'dc.ApiHarvester',
    InternalApi = 'dc.InternalApiHarvester',
    BigQuery = 'dc.BigQueryHarvester',
    SQL = 'dc.SQLHarvester',
    SAPHana = 'dc.SAPHanaHarvester',
}

export enum PreprocessingBlockId {
    Mapping = 'dc.DataMapper',
    Cleaning = 'dc.DataCleaner',
    Encryption = 'dc.DataEncryptor',
    Anonymisation = 'dc.DataAnonymiser',
    Loader = 'dc.Loader',
}

export enum ExecutionLocation {
    Cloud = 'cloud',
    Local = 'local',
}

export const blockIdToTaskMap: Record<HarvesterBlockId | PreprocessingBlockId, TaskType> = {
    [HarvesterBlockId.File]: 'harvester',
    [HarvesterBlockId.OtherFile]: 'harvester',
    [HarvesterBlockId.LargeFiles]: 'harvester',
    [HarvesterBlockId.Kafka]: 'harvester',
    [HarvesterBlockId.MQTT]: 'harvester',
    [HarvesterBlockId.ExternalKafka]: 'harvester',
    [HarvesterBlockId.ExternalMQTT]: 'harvester',
    [HarvesterBlockId.Api]: 'harvester',
    [HarvesterBlockId.InternalApi]: 'harvester',
    [HarvesterBlockId.BigQuery]: 'harvester',
    [HarvesterBlockId.SQL]: 'harvester',
    [HarvesterBlockId.SAPHana]: 'harvester',
    [PreprocessingBlockId.Mapping]: 'mapping',
    [PreprocessingBlockId.Cleaning]: 'cleaning',
    [PreprocessingBlockId.Encryption]: 'encryption',
    [PreprocessingBlockId.Anonymisation]: 'anonymisation',
    [PreprocessingBlockId.Loader]: 'loader',
};

export const HarvesterBlockIds = Object.values(HarvesterBlockId);
