export enum TaskStatus {
    Draft = 'draft',
    Ready = 'ready',
    Deprecated = 'deprecated',
    Updating = 'updating',
}

export enum TaskExecutionStatus {
    Draft = 'draft',
    Queued = 'queued',
    Running = 'running',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Ready = 'ready',
    Deprecated = 'deprecated',
    Updating = 'updating',
}

export type TaskType = 'harvester' | 'mapping' | 'cleaning' | 'encryption' | 'anonymisation' | 'loader';
