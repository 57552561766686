import { ESortingOption } from '@/app/types';
import { isNil } from 'ramda';
export class DataCheckinSortingOption extends ESortingOption {
    static readonly TITLE_ASC = new DataCheckinSortingOption('title', true, 'Title Ascending');

    static readonly TITLE_DESC = new DataCheckinSortingOption('title', false, 'Title Descending');

    static readonly DATE_CREATED_DESC = new DataCheckinSortingOption(
        'date_created',
        false,
        'Date created newest first',
    );

    static readonly DATE_CREATED_ASC = new DataCheckinSortingOption('date_created', true, 'Date created oldest first');

    static readonly DATE_UPDATED_DESC = new DataCheckinSortingOption(
        'date_updated',
        false,
        'Date updated newest first',
    );

    static readonly DATE_UPDATED_ASC = new DataCheckinSortingOption('date_updated', true, 'Date updated oldest first');

    static readonly STATUS_ASC = new DataCheckinSortingOption('status', true, 'Status Ascending');

    static readonly STATUS_DESC = new DataCheckinSortingOption('status', false, 'Status Descending');

    static all(): ESortingOption[] {
        return [...super.all(), ...this.local()];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        const calculatedDefault = super.calculateDefault(relevanceAvailable);
        if (!isNil(calculatedDefault)) return calculatedDefault;

        return this.DATE_UPDATED_DESC;
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        return [...super.available(relevanceAvailable), ...this.local()];
    }

    private static local(): DataCheckinSortingOption[] {
        return [
            this.DATE_CREATED_DESC,
            this.DATE_CREATED_ASC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.STATUS_DESC,
            this.STATUS_ASC,

            this.TITLE_DESC,
            this.TITLE_ASC,
        ];
    }
}
