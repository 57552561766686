export const SAMPLE_LIMIT = 1000 * 500; // 500KB

export enum FileType {
    CSV = 'csv',
    JSON = 'json',
    XML = 'xml',
    Parquet = 'parquet',
    Other = 'other',
}

export enum FileUploadStatus {
    Pending = 'PENDING',
}

export enum RetrievalType {
    Immediately = 'immediately',
    Once = 'once',
    Periodic = 'periodic',
    Polling = 'polling',
}

export enum KafkaProcessingOptions {
    RealTime = 'realTime',
    TimeBased = 'timeBased',
}

export enum KafkaSecurityProtocol {
    Plantext = 'PLAINTEXT',
    SSL = 'SSL',
    SaslPlaintext = 'SASL_PLAINTEXT',
    SaslSSL = 'SASL_SSL',
}

export enum KafkaSaslMechanism {
    None = 'None',
    Plain = 'PLAIN',
    ScramSha256 = 'SCRAM-SHA-256',
    ScramSha512 = 'SCRAM-SHA-512',
}

export enum SQLRetrievalMethod {
    Query = 'query',
    Table = 'table',
}

export enum SQLDatabaseType {
    MsSQL = 'mssql',
    PostgresSQL = 'postgresql',
    MySQL = 'mysql',
    MariaDB = 'mariadb',
}

export const ApiPaginationOptions = ['no', 'offset', 'page'] as const;
