export enum MappingStep {
    Info,
    Configuration,
    Review,
    Confirm,
}

export enum AlternateNaming {
    None = 'none',
    Original = 'original',
    Alias = 'alias',
}

export enum ValidationError {
    MissingMeasurementUnit,
    MissingDateFormat,
    MissingTimezone,
    MissingDateOrder,
    MissingOrder,
    LongerNestingLevel,
    ShorterNestingLevel,
    Multiple,
    Array,
    AliasFormat,
    CircularReference,
    DuplicateAlias,
    DuplicateField,
    DuplicateOrder,
}

export enum MappingFilter {
    All = 'all',
    Corrected = 'corrected',
    Invalid = 'invalid',
    Predicted = 'predicted',
    Selected = 'selected',
    Unidentified = 'unidentified',
}

export enum TransformationType {
    None = 'None',
    Alias = 'Alias',
    Annotation = 'Annotation',
    DatetimeFormat = 'DatetimeFormat',
    DecimalPoint = 'DecimalPoint',
    NestingLevel = 'NestingLevel',
    ThousandsSeparator = 'ThousandsSeparator',
    Timezone = 'Timezone',
    TypeCast = 'TypeCast',
    Unit = 'Unit',
}

export const InvalidMessages: Record<ValidationError, { message: string; description?: string }> = {
    [ValidationError.MissingMeasurementUnit]: { message: 'Missing measurement unit' },
    [ValidationError.MissingDateFormat]: { message: 'Missing date format' },
    [ValidationError.MissingTimezone]: { message: 'Missing timezone' },
    [ValidationError.MissingDateOrder]: { message: 'Missing date order' },
    [ValidationError.MissingOrder]: { message: 'Missing order' },
    [ValidationError.LongerNestingLevel]: {
        message: 'Invalid nesting levels',
        description:
            'The path leading to the target field you have selected is longer (i.e. it includes more arrays) than the path of the source field. You need to define how the mapping path inconsistency should be handled.',
    },
    [ValidationError.ShorterNestingLevel]: {
        message: 'Invalid nesting levels',
        description:
            'The path leading to the target field you have selected is shorter (i.e. it includes less arrays) than the path of the source field. You need to change the target path in this mapping to include more related concepts so as to be equivalent to the source path.',
    },
    [ValidationError.Multiple]: {
        message: 'Invalid mapping',
        description:
            'The target field you have selected for this source field (that is an array and takes multiple values) cannot take multiple values based on the data model.',
    },
    [ValidationError.Array]: {
        message: 'Invalid mapping',
        description:
            'The target field you have selected for this source field appears in the mapping of multiple source fields and at least one of the source fields is an array. You need to change the mapping to another target field having in mind that it is possible to: (a) map just 1 array source field to a target field that accepts multiple values; or (b) to map multiple source fields (that are not arrays) to one target field that accepts multiple values.',
    },
    [ValidationError.AliasFormat]: {
        message: 'Invalid alias format',
        description: 'Alias must contain only alphanumeric characters, dashes and underscores.',
    },
    [ValidationError.CircularReference]: {
        message: 'Invalid mapping',
        description:
            'Circular reference of the same concept. The related concept you have selected for the mapping is the same as a previous concept that appears in the target path. You need to select a different concept or remove the intermediate concepts in order to proceed.',
    },
    [ValidationError.DuplicateAlias]: {
        message: 'Duplicate alias',
        description: 'The alias name is used by another field',
    },
    [ValidationError.DuplicateField]: {
        message: 'Duplicate field',
        description:
            'The target field you have selected for this source field appears in the mapping of additional source fields, but it cannot take multiple values based on the data model.',
    },
    [ValidationError.DuplicateOrder]: {
        message: 'Duplicate order',
        description:
            'The order you have selected for the specific field is the same as in other field(s) with the same mapping.',
    },
};

export const alternateNamingOptions = [
    {
        id: AlternateNaming.None,
        label: 'No alternate naming required',
        description: 'Only the naming provided by the selected data model will be available',
    },
    {
        id: AlternateNaming.Original,
        label: 'Store the source/original field names',
        description: 'The original field names that appear in the source data will be stored and available',
    },
    {
        id: AlternateNaming.Alias,
        label: 'Define an alias for selected fields',
        description: 'An alias can be defined and stored for the  fields of your choice',
    },
];

export const playgroundFilters = [
    { label: MappingFilter.All, tooltip: 'All concepts that appear in the source data' },
    {
        label: MappingFilter.Predicted,
        tooltip: 'The concepts for which there is an automatic prediction that needs to be verified.',
    },
    {
        label: MappingFilter.Corrected,
        tooltip: 'The concepts for which the mapping to the Common Data Model was manually corrected.',
    },
    {
        label: MappingFilter.Unidentified,
        tooltip: ' The concepts for which a proper mapping to the Common Data Model is not yet identified.',
    },
    {
        label: MappingFilter.Invalid,
        tooltip:
            'The concepts for which there are errors in the mapping to the Common Data Model or additional information needs to be provided. They are displayed once you validate the mapping.',
    },
    {
        label: MappingFilter.Selected,
        tooltip: 'The concepts that have been manually selected and for which the sample data are displayed.',
    },
];
