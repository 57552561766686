import { AxiosRequestConfig } from 'axios';
import { AssetFacetedQuery, AssetQuery } from '../types';

const endpoint = '/api/search';

export default {
    search: (data: AssetQuery) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    facetedSearch: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets`, data } as AxiosRequestConfig),
    myJobsSearch: (data: any) => ({ method: 'POST', url: `${endpoint}/jobs/mine`, data } as AxiosRequestConfig),
    myWorkflowsSearch: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/workflows/mine`, data } as AxiosRequestConfig),
    sharedJobsSearch: (data: any) => ({ method: 'POST', url: `${endpoint}/jobs`, data } as AxiosRequestConfig),
    sharedWorkflowsSearch: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/workflows`, data } as AxiosRequestConfig),
    preview: (id: number, data: object) => ({ method: 'POST', url: `${endpoint}/preview`, data } as AxiosRequestConfig),
    retrievalQueries: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/retrieval-queries`, data } as AxiosRequestConfig),
};
